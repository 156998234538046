<template>
    <b-row>
        <b-col lg="12" class="mb-1">
            <div class="d-flex flex-row justify-content-center align-items-center" style="height: 100%;">
                <b-card-text class="text-center">
                    <b-container>
                        <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" to="/utilisateurs/add">
                            <feather-icon class="text-primary mr-50 align-middle" icon="PlusSquareIcon" size="30"/>
                            <span class="text-primary align-middle">Ajouter Utilisateur</span>
                        </b-button>
                    </b-container>
                </b-card-text>
            </div>
        </b-col>

        <b-col lg="12">
            <b-card>
                <!-- table -->
                <vue-good-table
                    :columns="columns"
                    :rows="utilisateurs"
                    :pagination-options="{enabled: true, perPage:pageLength}"
                    :initialSortBy="initialSortBy"
                    :theme="isDark ? 'nocturnal' : ''"
                    styleClass="vgt-table bordered tableau"
                    >
                >
                    <template #emptystate>
                        <div class="text-center"> Aucun utilisateur n'a été trouvé </div>
                    </template>

                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'action'" class="text-nowrap text-center">
                            <b-button
                                size="sm"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="success"
                                class="btn-icon"
                                @click="editUtilisateur(props.row.id)"
                            >
                                <feather-icon icon="Edit2Icon" size="1x"/>
                            </b-button>

                            <b-button
                                size="sm"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="danger"
                                class="btn-icon ml-1"
                                @click="confirmDeleteUtilisateur(props.row.id, props.row.username, removeUtilisateurFromList)"
                            >
                                <feather-icon icon="TrashIcon" size="1x"/>
                            </b-button>
                        </span>

                        <span v-else-if="props.column.field === 'roles'" class="text-nowrap">
                            <span>{{props.row.roles.map(r => r.substring(5)).filter(r => !["ADMINISTRATIF", "USER"].includes(r)).join(", ")}}</span>
                        </span>

                        <template v-else-if="props.column.field === 'active'" class="text-nowrap">
                            <div class="text-center text-primary"><feather-icon icon="CheckCircleIcon" size="18" v-if="props.row.active" /></div>
                        </template>
                    </template>

                    <template
                        slot="pagination-bottom"
                        slot-scope="props"
                    >
                        <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap">
                            </span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['25', '50', '100']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                            <span class="text-nowrap "> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
                        </div>
                        </div>
                    </template>
                </vue-good-table>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    import 'vue-good-table/dist/vue-good-table.css'
    import { VueGoodTable } from 'vue-good-table'

    import utilisateurApi from "@/api/utilisateurApi"
    import { confirmDeleteUtilisateur, deleteUtilisateur } from "@/views/setups/utilisateur"

    import useAppConfig from '@core/app-config/useAppConfig'
    import { computed } from '@vue/composition-api'

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {
        components: {
            VueGoodTable,
            ToastificationContent
        },

        // TODO se baser sur columns pour extraire les expectedFields utilisés dans le setup du formulaire ???
        data() {
            return {
                utilisateurs: [],
                initialSortBy: [{field: 'nom', type: 'asc'}],
                pageLength: 25,
                columns: [
                    {
                        label: 'Nom Utilisateur',
                        field: 'username',
                        filterOptions: {enabled: true,placeholder: 'Nom Utilisateur ...',},
                    },
                    {
                        label: 'Prénom',
                        field: 'firstname',
                        filterOptions: {enabled: true,placeholder: 'Prénom ...',},
                    },
                    {
                        label: 'Nom',
                        field: 'lastname',
                        filterOptions: {enabled: true,placeholder: 'Nom ...',},
                    },
                    {
                        label: 'Email',
                        field: 'email',
                        filterOptions: {enabled: true,placeholder: 'Email ...',},
                    },
                    {
                        label: 'Groupe',
                        field: 'roles',
                    },
                    {
                        label: 'Activé',
                        field: 'active',
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        width: '10%',
                        sortable: false
                    },
                ],
            }
        },

        beforeMount() {
            this.refreshUtilisateurs()
        },

        setup() {
            const { skin } = useAppConfig()
            const isDark = computed(() => skin.value === 'dark')

            return { skin, isDark, deleteUtilisateur, confirmDeleteUtilisateur}
        },

        methods: {
            async refreshUtilisateurs() {
                /* console.log(process.env.NODE_ENV == "development") */
                this.utilisateurs = await utilisateurApi.getUtilisateurs();
            },

            editUtilisateur(utilisateurId) {
                this.$router.push({path: `/utilisateurs/${utilisateurId}/edit` })
            },

            removeUtilisateurFromList(utilisateurId) {
                let index = this.utilisateurs.findIndex(c => c.id == utilisateurId)
                if(index != -1)
                    this.utilisateurs.splice(index, 1);
            },

            showToast(variant, text, icon) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        icon,
                        text,
                        variant
                    },
                }, {position: "bottom-right", timeout: 2500})
            },
        }
    }
</script>

<style>
    .dropdown > button{
        padding: 0 !important;
    }
</style>